import React, { useEffect, useRef, useState } from 'react';
import { Toast as TpaToast, ToastSkin } from 'wix-ui-tpa/cssVars';
import { classes } from './index.st.css';

export interface ToastProps {
  informational?: boolean;
  onClose(): void;
}

const OFFSET = 50;

export const Toast: React.FC<ToastProps> = ({ onClose, informational, children }) => {
  const [visible, setVisible] = useState(false);
  const wrapper = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const { top } = wrapper.current?.getBoundingClientRect() ?? {};
    if (top && top - OFFSET < 0) {
      window.scrollBy({
        left: 0,
        top: top - OFFSET,
        behavior: 'smooth',
      });
    }
    const timeout = setTimeout(() => setVisible(true), 0);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className={classes.wrapper} ref={wrapper}>
      <div className={classes.toast}>
        <TpaToast
          skin={informational ? ToastSkin.status : ToastSkin.error}
          data-hook="toast"
          shouldAnimate
          shouldShowCloseButton
          isShown={visible}
          onClose={() => onClose()}
        >
          {children}
        </TpaToast>
      </div>
    </div>
  );
};
