import React from 'react';
import { TextButton, TextButtonPriority } from 'wix-ui-tpa/cssVars';
import { classes, st } from './Actions.st.css';

interface ActionProps {
  dataHook?: string;
  onClick: () => void;
}

export const Action: React.FC<ActionProps> = ({ dataHook, onClick, children }) => {
  return (
    <TextButton data-hook={dataHook} className={st(classes.root)} priority={TextButtonPriority.link} onClick={onClick}>
      {children}
    </TextButton>
  );
};
