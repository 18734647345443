import React from 'react';

interface LocaleContext {
  locale: string | undefined;
}

const LocaleContainer = React.createContext<LocaleContext>({ locale: undefined });

export const LocaleProvider: React.FC<LocaleContext> = ({ children, locale }) => {
  return <LocaleContainer.Provider value={{ locale }}>{children}</LocaleContainer.Provider>;
};

export const useLocale = () => React.useContext(LocaleContainer);
