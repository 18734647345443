import currencyToSymbolMap from 'currency-symbol-map/map';
import { useCurrencyFormatter, CreateCurrencyFormatter } from '@wix/yoshi-flow-editor';
import { useLocale } from './useLocale';

const fallbackError = <T extends unknown>(callback: () => T, fallbackValue: T): T => {
  try {
    return callback();
  } catch {
    return fallbackValue;
  }
};

export interface FormattedPriceData {
  price: string;
  currency: string;
  fullPrice: string;
}

export function usePrice(params: {
  value: string | undefined;
  currency: string | undefined;
  hideTrailingFractionZeroes?: boolean;
}): FormattedPriceData {
  const { value, currency, hideTrailingFractionZeroes } = params;
  const { locale } = useLocale();

  return getFormattedPriceData({
    createCurrencyFormatter: useCurrencyFormatter,
    locale,
    value,
    currency,
    hideTrailingFractionZeroes,
  });
}

export function getFormattedPriceData(params: {
  createCurrencyFormatter: CreateCurrencyFormatter;
  locale?: string;
  value?: string;
  currency?: string;
  hideTrailingFractionZeroes?: boolean;
}): FormattedPriceData {
  const { createCurrencyFormatter, locale, value, currency, hideTrailingFractionZeroes } = params;
  const formatNumberParts = createCurrencyFormatter({ parts: true, language: locale });
  const numberParts = value && currency ? fallbackError(() => formatNumberParts({ value, currency }), []) : [];
  const fraction = numberParts.find((item) => item.type === 'fraction')?.value ?? '';
  const allZeroes = /^0+$/g.test(fraction);
  const forceZeroFractionDigits = allZeroes && hideTrailingFractionZeroes;
  const formatFullPrice = createCurrencyFormatter({
    language: locale,
    minimumFractionDigits: forceZeroFractionDigits ? 0 : undefined,
    maximumFractionDigits: forceZeroFractionDigits ? 0 : undefined,
  });
  const formatPrice = createCurrencyFormatter({
    style: 'decimal',
    language: locale,
    minimumFractionDigits: allZeroes ? undefined : fraction.length,
  });

  return {
    price: value && currency ? fallbackError(() => formatPrice({ value, currency }), value) : value ?? '',
    currency:
      numberParts?.find((item) => item.type === 'currency')?.value ??
      (currency ? currencyToSymbolMap[currency.toUpperCase()] ?? currency : ''),
    fullPrice: value && currency ? fallbackError(() => formatFullPrice({ value, currency }), value) : value ?? '',
  };
}
