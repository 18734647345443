import React, { useEffect, useState } from 'react';
import { Text, TextButton, TextField } from 'wix-ui-tpa/cssVars';
import { Tag } from '@wix/wix-ui-icons-common';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { CouponInputMode, ErrorMessage } from '../../../../../types/common';
import { CheckoutStyledButton } from '../CheckoutStyledButton';
import { classes } from './CouponInput.st.css';

interface CouponInputProps {
  mode: CouponInputMode;
  value: string;
  error?: ErrorMessage;
  onApplyClick: (couponCode: string) => void;
  onRemoveClick: () => void;
  disabled?: boolean;
  loading?: boolean;
}

export const CouponInput = ({
  mode: initialMode,
  value: initialValue,
  error: initialError,
  onApplyClick,
  onRemoveClick,
  disabled,
  loading,
}: CouponInputProps) => {
  const { t } = useTranslation();
  const [mode, setDisplayMode] = useState<CouponInputMode>(initialMode);
  const [error, setError] = useState<ErrorMessage | undefined>(initialError);
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setError(initialError);
  }, [initialError]);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    setDisplayMode(initialMode);
  }, [initialMode]);

  if (mode === 'trigger') {
    return (
      <TextButton
        data-hook="coupon-input-trigger-button"
        prefixIcon={<Tag />}
        onClick={() => setDisplayMode('input')}
        className={classes.enterCouponButton}
      >
        {t('payment.coupon-input.trigger')}
      </TextButton>
    );
  }
  if (mode === 'input') {
    return (
      <div className={classes.couponInput} data-hook="coupon-input">
        <TextField
          label={t('payment.coupon-input.add-coupon-code')}
          id="coupon-input-text-field"
          data-hook="coupon-input-text-field"
          className={classes.textField}
          placeholder={t('payment.coupon-input.placeholder')}
          value={value}
          onChange={(e) => {
            setError(undefined);
            setValue(e.target.value);
          }}
          withClearButton
          onClear={() => {
            setError(undefined);
            setValue('');
          }}
          error={Boolean(error)}
          errorMessage={error?.message}
          newErrorMessage
        />
        <CheckoutStyledButton
          dataHook="coupon-input-apply-button"
          className={classes.applyButton}
          loading={loading}
          disabled={Boolean(error) || !value}
          onClick={() => onApplyClick(value)}
          label={t('payment.coupon-input.apply')}
        />
      </div>
    );
  }
  if (mode === 'coupon') {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Tag size="29" />
          <Text className={classes.coupon}>{value}</Text>
        </div>{' '}
        <TextButton
          className={classes.removeButton}
          data-hook="coupon-input-remove-button"
          disabled={disabled}
          onClick={onRemoveClick}
        >
          {t('payment.coupon-input.remove')}
        </TextButton>
      </div>
    );
  }
  return null;
};
