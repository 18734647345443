import React from 'react';
import { DefaultSettingsAdapter } from '../DefaultSettingsAdapter';
import settingsParams from '../settingsParams';
import stylesParams from '../stylesParams';
import { WidgetContext } from './WidgetContext';

type PackagePickerWidgetContextProps = {
  locale?: string;
  width: number;
};
export const PackagePickerWidgetContext: React.FC<PackagePickerWidgetContextProps> = ({ children, locale, width }) => {
  return (
    <WidgetContext
      settingsAdapterClass={DefaultSettingsAdapter}
      settingsParams={settingsParams}
      stylesParams={stylesParams}
      locale={locale}
      width={width}
    >
      {children}
    </WidgetContext>
  );
};
