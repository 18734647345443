import React from 'react';
import { Text } from 'wix-ui-tpa/cssVars';
import { useFormPreview, FormValues } from '@wix/form-viewer/widget';
import { TFunction, useTranslation } from '@wix/yoshi-flow-editor';
import { classes } from './FormPreview.st.css';

interface FormPreviewProps {
  formId: string;
  formValues: FormValues;
}

const renderFormValue = (value: any, t: TFunction) => {
  if (typeof value === 'string') {
    return value;
  } else if (typeof value === 'number') {
    return value;
  } else if (typeof value === 'boolean') {
    return t(value ? 'form-preview.checkbox.true' : 'form-preview.checkbox.false');
  } else if (Array.isArray(value)) {
    return value.length ? value.join(', ') : '-';
  }
  return '-';
};

export const FormPreview = ({ formId, formValues }: FormPreviewProps) => {
  const { t } = useTranslation();
  const formattedValues = useFormPreview(formId, formValues);
  if (!formattedValues?.length) {
    return null;
  }

  return (
    <div className={classes.root}>
      {formattedValues.map(({ label, value }) => (
        <Text className={classes.entry} tagName="div">
          {label}
          {label ? ':' : null} {renderFormValue(value, t)}
        </Text>
      ))}
    </div>
  );
};
