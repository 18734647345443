import type { Order, SpannedPrice } from '@wix/ambassador-pricing-plans-v2-order/types';
import type { PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';
import { TPA_EXPERIMENTS } from '@wix/pricing-plans-common/experiments';
import { getPlanPrice, getSetupFeeAmount } from '@wix/pricing-plans-utils';
import { useExperiments } from '@wix/yoshi-flow-editor';
import { usePrice } from '../../../../../hooks';

export function useOrderSummary(plan: PublicPlan, spannedPrices: SpannedPrice[], order: Order | undefined) {
  const { experiments } = useExperiments();
  const isSetupFeeEnabled = experiments.enabled(TPA_EXPERIMENTS.SETUP_FEE);

  const planPrice = getPlanPrice(plan);
  const price = spannedPrices[0]?.price;
  const setupFee = getSetupFeeAmount(plan);

  const isFreeTrailAvailable = Boolean(!order || order.freeTrialDays);
  const hasFreeTrial = Boolean(plan.pricing?.freeTrialDays);
  const hasZeroTotal = isSetupFeeEnabled && hasFreeTrial && isFreeTrailAvailable;
  const totalPrice = hasZeroTotal ? '0' : price?.total ?? planPrice.value;

  const { fullPrice: planPriceFormatted } = usePrice({ value: planPrice.value, currency: planPrice.currency });
  const { fullPrice: total } = usePrice({ value: totalPrice, currency: planPrice.currency });
  const { fullPrice: subtotal } = usePrice({ value: price?.subtotal, currency: planPrice.currency });
  const { fullPrice: taxAmount } = usePrice({ value: price?.tax?.amount, currency: planPrice.currency });
  const { fullPrice: setUpFeeFormatted } = usePrice({ value: setupFee, currency: planPrice.currency });

  const amount = price?.coupon?.amount;
  const { fullPrice: couponAmount } = usePrice({
    value: amount ? `-${amount}` : undefined,
    currency: planPrice.currency,
  });

  return { total, subtotal, taxAmount, couponAmount, planPrice: planPriceFormatted, setupFee: setUpFeeFormatted };
}
