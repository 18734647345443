import React, { memo, useMemo } from 'react';
import { useTPAComponentsContext } from 'wix-ui-tpa';
import { TPAComponentsProvider } from 'wix-ui-tpa/cssVars';
import { useSettings } from '@wix/tpa-settings/react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { SettingsAdapterProvider, StylesParamsProvider } from '../../../hooks';
import { LocaleProvider } from '../../../hooks/useLocale';
import type { DefaultSettingsAdapter } from '../DefaultSettingsAdapter';
import type { PackagePickerSettingsParams } from '../settingsParams';
import type { PackagePickerStylesParams } from '../stylesParams';
import { WidgetBreakpoints } from './WidgetBreakpoints';

type WidgetContextProps = {
  settingsAdapterClass: typeof DefaultSettingsAdapter;
  settingsParams: PackagePickerSettingsParams;
  stylesParams: PackagePickerStylesParams;
  locale?: string;
  width: number;
};

export const WidgetContext: React.FC<WidgetContextProps> = memo(
  ({ children, settingsAdapterClass: Adapter, settingsParams, stylesParams, locale, width }) => {
    const settings = useSettings();
    const tpaComponentsContext = useTPAComponentsContext();
    const settingsAdapter = useMemo(() => new Adapter(settings, settingsParams), [settings]);
    const { isRTL } = useEnvironment();
    return (
      <TPAComponentsProvider value={tpaComponentsContext}>
        <WidgetBreakpoints width={width}>
          <StylesParamsProvider stylesParams={stylesParams}>
            <SettingsAdapterProvider adapter={settingsAdapter}>
              <LocaleProvider locale={locale}>
                <div dir={isRTL ? 'rtl' : 'ltr'}>{children}</div>
              </LocaleProvider>
            </SettingsAdapterProvider>
          </StylesParamsProvider>
        </WidgetBreakpoints>
      </TPAComponentsProvider>
    );
  },
);
